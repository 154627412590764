import { Controller } from "stimulus";
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["countdown"]
  static values = { delay: String }

  initialize () {
    this.hide = this.hide.bind(this)
  }

  connect () {
    useTransition(this, {
      enterActive: 'transition ease-out duration-100',
      enterFrom: 'transform opacity-0 scale-95',
      enterTo: 'transform opacity-100 scale-100',
      leaveActive: 'transition ease-in duration-75',
      leaveFrom: 'transform opacity-100 scale-100',
      leaveTo: 'transform opacity-0 scale-95'
    })

    this.enter()

    this.timeout = setTimeout(this.hide, this.delayValue)
  }

  async hide () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    await this.leave()

    this.element.remove()
  }
}
